<template>
    <tbody>
        <tr
            v-for="(item, index) in items"
            :key="index"
            :class="`${isStatusClosed(item) ? 'disabled' : ''}`"
        >
            <!-- === === AVATAR === === -->
            <td>
                <v-avatar size="30" color="secondary">
                    <img
                        v-if="item.picture"
                        :src="UserPicture(item.picture)"
                        alt="avatar"
                    />
                    <span class="white--text" v-else
                        >{{ item.name[0] }}{{ item.lastname[0] }}</span
                    >
                </v-avatar>
            </td>
            <!-- === === NAME === === -->
            <td class="truncate">{{ item.name }}</td>

            <!-- === === LASTNAME === === -->
            <td class="truncate">{{ item.lastname }}</td>

            <!-- === === POSITION === === -->
            <td class="truncate">{{ item.position }}</td>
            <!-- === === STATUS === === -->
            <td>
                <v-chip
                    :color="$get(item, 'status.color.background', '#555555')"
                    :style="`color: ${$get(
                        item,
                        'status.color.font',
                        '#FFFFFF'
                    )}`"
                    dark
                    label
                    small
                    >{{ $get(item, 'status.name', $t('users:empty')) }}</v-chip
                >
            </td>

            <!-- === === AKCJA === === -->
            <td style="text-align: right;">
                <v-icon small v-if="hasFile(item.folders)"
                    >mdi-paperclip</v-icon
                >
                <v-menu bottom v-if="del()">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon small v-bind="attrs" v-on="on">
                            <v-icon color="iconGray" small
                                >mdi-dots-vertical</v-icon
                            >
                        </v-btn>
                    </template>
                    <v-list dense>
                        <!-- === === REMOVE === === -->
                        <v-list-item
                            @click="
                                openConfirm = true
                                idToDelete = item._id
                            "
                        >
                            <v-list-item-icon class="mr-0">
                                <v-icon color="red" small>mdi-delete</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Usuń</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <v-btn
                    icon
                    small
                    @click="$router.push({ path: `/users/${item._id}` })"
                >
                    <v-icon color="secondary" small
                        >mdi-arrow-right-thick</v-icon
                    >
                </v-btn>
            </td>
        </tr>
        <ConfirmModal
            :openConfirm="openConfirm"
            v-on:close-confirm="closeConfirmModal"
        >
            <div slot="main">
                <ConfirmContent />
            </div>
            <div slot="buttons">
                <div class="d-flex justify-center">
                    <v-btn
                        color="rgb(117, 117, 117)"
                        width="110"
                        height="40"
                        @click="closeConfirmModal()"
                        class="white--text mx-2"
                        >{{ $t('cancel') }}</v-btn
                    >
                    <v-btn
                        width="110"
                        height="40"
                        color="#445d75"
                        class="white--text mx-2"
                        @click="remove()"
                        >{{ $t('remove') }}</v-btn
                    >
                </div>
            </div>
        </ConfirmModal>
    </tbody>
</template>

<script>
import ConfirmContent from '../ConfirmModal/ConfirmContent'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    components: {
        ConfirmContent,
    },
    props: ['items'],
    data: () => ({ openConfirm: false, idToDelete: null, permissions: false }),
    computed: {
        ...mapGetters([
            'getProfilePicture',
            'getProfileDetails',
            'getRolesRegistries',
            'getUrl',
        ]),
    },
    methods: {
        ...mapActions(['fetchRole']),
        ...mapMutations(['setCheckRole']),
        UserPicture(payload) {
            let picture = `${process.env.VUE_APP_SERVER}/secured/profile/${payload}`
            return picture
        },
        ...mapActions(['deleteUser']),
        async remove() {
            let result = await this.deleteUser({ id: this.idToDelete })
            if (result) this.openConfirm = false
        },
        openConfirmModal() {
            this.openConfirm = true
        },
        del() {
            return this.checkPermissions('DELETE')
        },
        closeConfirmModal() {
            this.openConfirm = false
        },
    },
}
</script>
