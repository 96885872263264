<template>
    <v-container class="p-relative">
        <v-row>
            <v-col cols="12" class="py-0">
                <RegistryHeader
                    :title="getUsersRegistry.name"
                    :avatarData="getUsersRegistry.currentMenager"
                    @clearSearch="clearTable"
                />
                <Table
                   :title="getUsersRegistry.name"
                    :items="getUsersTable.items"
                    :headers="headers"
                    :length="getUsersTable.pages"
                    :page="getUsersTable.page"
                    :defaultFields="getUsersSearchFields.searchFields"
                    @search="setSearch"
                    @clear-table="clearTable"
                    @change-page="setPage"
                    @sort="setSort"
                    :loading="getUsersTable.loading"
                >
                    <Columns slot="columns" :items="getUsersTable.items" />
                </Table>
                <Modal
                    :title="$t('global:addition')"
                    :open="open"
                    v-on:close="closeModal"
                >
                    <AEContent slot="AEContent" />
                    <Buttons
                        slot="buttons"
                        :action="'create'"
                        @closeModal="open = false"
                    />
                </Modal>
                <div class="d-flex justify-end buttons--action">
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                fab
                                data-cy="addEntry"
                                dark
                                v-if="add()"
                                v-on="on"
                                small
                                @click="addUser()"
                                class="buttons--add margin--1"
                            >
                                <v-icon small>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('users:addWorker') }}</span>
                    </v-tooltip>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Columns from './../../../components/Registries/Users/Table/Columns'
import AEContent from './../../../components/Registries/Users/Modal/Content'
import Buttons from './../../../components/Registries/Users/Modal/Buttons'
import store from './../../../store/index'
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
    i18nOptions: { namespaces: ['users', 'global'] },
    components: {
        Columns,
        Buttons,
        AEContent,
    },
    computed: {
        ...mapGetters([
            'getUsersTable',
            'getUsersSearchFields',
            'getUsersRegistry',
            'getSearch',
        ]),
    },
    methods: {
        ...mapMutations([
            'setUsersTable',
            'clearUsersTable',
            'clearUserModal',
            'clearUsersErrors',
        ]),
        ...mapActions(['fetchUsersTable', 'fetchRolesList']),
        add() {
            return this.checkPermissions('CREATE')
        },

        addUser() {
            this.open = true
            this.fetchRolesList({
                select: 'name isDeleted',
                where: { isDeleted: false },
            })
            this.clearUserModal()
        },
        closeModal() {
            this.clearUsersErrors()
            this.open = false
        },
        setSearch(search) {
            this.setUsersTable({ search, page: 1 })
            this.fetchUsersTable()
        },
        setPage(page) {
            this.setUsersTable({ page })
            this.fetchUsersTable()
        },
        setSort(sort) {
            this.setUsersTable({ sort })
            this.fetchUsersTable()
        },
        clearTable() {
            this.getSearch.text = ''
            this.clearUsersTable()
            this.fetchUsersTable()
        },
    },

    data() {
        return {
            open: false,
            length: 19,

            headers: [
                {
                    text: '',
                    value: 'name',
                    width: '3%',
                },
                {
                    text: this.$t('users:name'),
                    value: 'name',
                    width: '15%',
                    showSearch: true,
                },
                {
                    text: this.$t('users:lastname'),
                    value: 'lastname',
                    width: '15%',
                    showSearch: true,
                },
                {
                    text: this.$t('users:position'),
                    value: 'position',
                    width: '48%',
                    showSearch: true,
                },
                {
                    text: this.$t('users:status'),
                    value: 'status.name',
                    width: '100px',
                    class: 'table-status',
                    showSearch: true,
                },
                {
                    text: '',
                    value: 'arrow',
                    align: 'right',
                    width: '150px',
                    sortable: false,
                },
            ],
        }
    },
    beforeRouteEnter(to, from, next) {
        if (from.matched[1] && from.matched[1].path !== '/users/:id')
            store.commit('clearUsersTable')
        store.dispatch('fetchUsersTable').then(() => next())
    },
}
</script>
